<template>
  <span>
    <DxDataGrid
      key-expr="id"
      :data-source="dataSource"
      :focused-row-enabled="true"
      :allow-column-reordering="true"
      :allow-column-resizing="true"
      :column-auto-width="true"
      :show-borders="true"
      :row-alternation-enabled="true"
      @init-new-row="handleEvent('init-new-row', $event)"
      @option-changed="handleEvent('option-changed', $event)"
      @row-removed="handleEvent('row-removed', $event)"
    >
      <DxEditing
        :allow-updating="false"
        :allow-deleting="true"
        :allow-adding="false"
        :use-icons="true"
        mode="row"
      />

      <DxColumn type="buttons">
        <DxButton icon="copy" :onClick="onDuplicate" />
        <DxButton name="delete" />
      </DxColumn>

      <DxColumnFixing :enabled="true" />
      <DxSorting mode="multiple" />
      <DxFilterRow :visible="true" />
      <DxHeaderFilter :visible="true" />

      <DxColumn
        v-for="(item, index) in dataColumns"
        :key="index"
        :data-field="item.field"
        :caption="item.caption"
        :cell-template="item.cellTemplate"
        :alignment="item.alignment"
        :format="item.format"
      />
    </DxDataGrid>
  </span>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    items(v) {
      this.dataSource = v;
    },
  },
  data: () => ({
    dataSource: [],
    dataColumns: [
      { caption: 'Renewal No.', field: 'renewal_no' },
      {
        caption: 'Start Date',
        field: 'preview_on_date',
        dataType: 'date',
        format: 'dd/MM/yyyy',
        alignment: 'right',
      },
      {
        caption: 'End Date',
        field: 'preview_due_date',
        dataType: 'date',
        format: 'dd/MM/yyyy',
        alignment: 'right',
      },
      {
        caption: 'Reminder Date',
        field: 'preview_reminder_date',
        dataType: 'date',
        format: 'dd/MM/yyyy',
        alignment: 'right',
      },
      { caption: 'Renewal Status', field: 'renewal_status' },
      { caption: 'Last Serial No.', field: 'last_serial_no' },
      {
        caption: 'Payment Date',
        field: 'preview_payment_date',
        dataType: 'date',
        format: 'dd/MM/yyyy',
        alignment: 'right',
      },
      {
        caption: 'Payment Amount',
        field: 'payment_amount',
        alignment: 'right',
        format: 'currency',
      },
      { caption: 'Receipt No.', field: 'receipt_no' },
    ],
  }),
  methods: {
    handleEvent(action, event) {},
    onDuplicate(event) {
      const { data } = event.row;
      this.$emit('on-duplicate', data);
    },
  },
};
</script>

<style scoped></style>
